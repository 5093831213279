import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import P from '../typo/P';
import H3 from '../typo/H3';

const Time = styled.time`
  font-size: 20px;
  min-width: 125px;
  bottom: -16px;
  padding: 3px 30px 1px;
  white-space: nowrap;
`;

const Title = styled(H3)``;

const Teaser = styled(P)``;

const NewsTeaser = ({content, title = 'Telekom In-Flight News'}) => {
  const news = content.filter(c => c.node.online === true).slice(0, 3);

  return (
    <section id="news" className="news-block gray-style">
      <div className="container">
        <h2>{title}</h2>
        <ul className="news-list">
          {news.map(({node: n}, k) => (
            <li key={k}>
              <Link to={`/news/${n.slug}`} className="image">
                <Img
                  fixed={n.postImage.fixed}
                  alt={n.title}
                  width="442"
                  height="295"
                  objectFit="cover"
                />
                <Time>{n.dateString}</Time>
              </Link>
              <div className="content">
                <Title className="h5">
                  <Link to={`/news/${n.slug}`}>{n.title}</Link>
                </Title>
                <Teaser>
                  {n.teaser} <br />
                  <br />
                  <Link to={`/news/${n.slug}`}>Read full article</Link>
                </Teaser>
              </div>
            </li>
          ))}
        </ul>
        <footer>
          {/*<a href="#" className="btn btn-white">
            see ALL NEWS
        </a> */}
        </footer>
      </div>
    </section>
  );
};

export default NewsTeaser;
